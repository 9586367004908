/* styles.css */
body {
  /*font-family: Arial, sans-serif;*/
  /*margin: 0;*/
  /*padding: 0;*/
  /*line-height: 1.6;*/
  color: white
}

.container-policy {
  max-width: 800px;
  margin: 20px auto;
  padding: 0 20px;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 30px;
}

/*p {*/
/*  margin: 10px 0;*/
/*}*/
